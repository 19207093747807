<template>
  <vue-final-modal v-slot="{ close }">
    <div class="loginmodal">
      <div class="art" :class="level"><!-- agreeart warningart -->
        <span class="img"></span>
        <p v-html="$t(code)"></p>
        <div class="btn">
          <template v-if="type == 'confirm'">
            <button class="yes" @click="$emit('confirm', 'yes', close)">{{$t('front.button.yes')}}</button>
            <button class="no" @click="$emit('confirm', 'no', close)">{{$t('front.button.no')}}</button>
          </template>

          <template v-if="type == 'check'">
            <button class="yes" @click="$emit('check', 'ok', close)">{{$t('front.button.confirm')}}</button>
          </template>
        </div>
        <button class="closeart" @click="onClose(close)"><img src="@/assets/img/icon_cancel.png" /></button>
      </div>
    </div>
  </vue-final-modal>

</template>

<script>

export default {
  name: 'Alert',
  props: {
    code: {
      type: String
    },
    type: {
      type: String
    },
    level: {
      type: String,
      default: 'warningart'
    }
  },
  data () {
    return {
      lang: 'ko',
      result: ''
    }
  },
  methods: {
    onClose (close) {
      close()
      // this.$emit('close', this.type, this.result)
    }
  }
}
</script>

<style scoped>
button {cursor: pointer}
.art {position: relative;width: 400px;margin: 0 auto;margin-top: 100px;padding: 84px 133px 72px;text-align: center;box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);border: solid 1px #109eef;border-radius: 38px;background: #2f3545;}
.art img {height: 80px;margin-bottom: 30px;}
.art h1 {font-size: 30px;font-weight: bold;}
.art p {margin-top: 28px;font-size: 18px;color: #fff;line-height: 1.5em;}
.art .btn {display: flex;justify-content: center;gap: 20px;}
.art .yes, .art .no {background: none;border: 0;box-sizing: border-box;width: 100%;height: 72px;font-size: 30px;border-radius: 10px;margin-top: 66px;}
.art .img {display: block;margin: 0 auto;margin-bottom: 30px;}
.agreeart .img {width: 90px;height: 65px;background: url('~@/assets/img/agreeart.svg')no-repeat;background-position: center;}
.agreeart>h1 {color: #fff;}
.agreeart .yes {border: 1px solid #14257a;color: #fff;background-image: linear-gradient( to bottom, #14257a, #177bba);}
.agreeart .no {background: #f3f3f3;color: #bebebe;border: 1px solid #bebebe;}
.warningart .img {width: 69px;height: 64px;background: url('~@/assets/img/warningart.svg')no-repeat;background-position: center;}
.warningart>h1 {color: #e1423c;}
.warningart .yes {border: 1px solid #e1423c;color: #fff;background-image: linear-gradient(to bottom, #e1423c, #a22924);}
.warningart .no {background: #555;color: #fff;border: 1px solid #555;}
.closeart {background: none;border: 0;position: absolute;right: 20px;top: 20px;}
.closeart img {height: 45px;}
</style>
<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
