<template>
  <div class="loginmodal" v-if="state !== 'ready'">
    <div class="modal-content agreeart">
      <p id="mainTM" class="modaltxt" v-if="type === 'header'"> <!--유저페이지에서-->
        <span v-if="state === 'ready'">게임 완전 종료 처리중입니다.<br> 잠시만(1분이내) 기다려주세요.</span>
        <span v-if="state === 'complete'">통합머니로 전환 완료</span>
        <span v-if="state === 'fail'">통합머니로 전환 실패. 고객센터로 문의주세요</span>
      </p>
      <p id="exchTM" class="modaltxt" v-if="type === 'cash'"> <!--출금시-->
        <span>통합머니 처리가 완료되지 않았습니다.<br> 통합머니 전환 버튼을 클릭해주세요.</span>
      </p>
      <p id="partnerTM" class="modaltxt" v-if="type === 'partner'"> <!--파트너페이지에서-->
        <span>해당회원의 통합머니 전환이 이루어지지 않았습니다.<br> 전환진행을 부탁드립니다.</span>
      </p>
      <button class="yes" @click="$emit('close')">확인</button>
    </div>
  </div>
</template>

<script>
import { setMoneyMerge } from '@/api/member'
export default {
  name: 'TMmodal',
  props: [
    'type',
    'isOpen'
  ],
  data () {
    return {
      state: 'ready'
    }
  },
  watch: {
    async isOpen () {
      if (this.isOpen) {
        if (this.type !== 'header') {
          this.state = 'ing'
        }
        await this.moneyMerge()
      }
    }
  },
  methods: {
    async moneyMerge () {
      console.log('moneyMerge')
      await setMoneyMerge({}).then(async res => {
        console.log(res)
        const result = res.data
        if (result.resultCode === '0') {
          // await this.onCheck('정상적으로 통합머니 전환이 완료되었습니다.')
          this.state = 'complete'
        } else if (result.resultCode === 'C009') {
          await this.onAlert('warningart', result.resultMessage)
          this.$emit('close')
        } else {
          // await this.onAlert('warningart', '오류가 발생하였습니다. 다시 시도해주세요.')
          this.state = 'fail'
        }
      })
    }
  }
}
</script>

<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>

<style scoped>
  .modal-content {background: #222; color:#fff; padding: 80px; width: auto;}
  .modaltxt{margin-top:0;text-align: left;font-size:18px;line-height: 2;display: flex;flex-direction: column;}
  .agreeart {display: flex;flex-direction: column;gap: 35px;}
  .agreeart .yes{cursor: pointer;background: #fff;color: #2f3545;padding: 10px 30px;border-radius: 30px;font-size: 12pt;font-weight: bold;border: 0;}
  br {display: none;}
  @media (max-width: 700px) {
    .modal-content {padding:40px 20px;margin: 50% auto; max-width: 90%; box-sizing: border-box;}
    .loginclose {top:20px; right: 20px;}
    .modaltxt {font-size: 14px;white-space: nowrap;text-align: center;}
    br {display: block;}
  }
</style>
