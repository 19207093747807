import { createWebHistory, createRouter } from 'vue-router'
import { DOCUMENT_TITLE_PREFIX } from '@/libs/constants'

import { stributor } from './stributor'
import { striNew } from './striNew'
import { attendendance } from './attendendance'
import { gameway } from './gameway'

import { liveCasino } from './game/liveCasino'
import { slotGame } from './game/slotGame'
import { sports } from './game/sports'
import { esports } from './game/esports'
import { miniGame } from './game/miniGame'
import { virtualGame } from './game/virtualGame'
import { phishingGame } from './game/phishingGame'
import { rotary } from './game/rotary'

import { result } from './result'
import { live } from './live'
import { board } from './board'
import { csCenter } from './csCenter'
import { money } from './money'
import { mypage } from './mypage'
import { gameLink } from './gameLink'
import { warning } from './warning'
import { getLangCookie, tokenCheck } from '@/libs/auth-helper'
import { coinWiki } from '@/router/coinWiki'
import { partner } from '@/router/partner'

const memberRoutes = [
  striNew,
  stributor,
  attendendance,
  gameway,
  liveCasino,
  slotGame,
  sports,
  esports,
  miniGame,
  virtualGame,
  phishingGame,
  rotary,
  result,
  live,
  board,
  csCenter,
  mypage,
  money,
  gameLink,
  coinWiki,
  gameLink,
  partner
]

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/views'),
    meta: {
      layout: 'default',
      guestOnly: true
    }
  },
  {
    path: '/coinresult',
    name: 'coin',
    component: () => import('@/views/coinresult'),
    meta: {
      layout: 'empty',
      guestOnly: true
    }
  },
  warning,
  {
    path: '/member',
    name: 'member',
    component: () => import('@/views/member'),
    children: memberRoutes
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'dashboard'
    }
  }
]

const router = createRouter({
  history: createWebHistory(), // 2.x대를 설치하시면 작동을 안합니다.
  routes,
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ top: 0, left: 0 })
      }, 0)
    })
  }
})

router.beforeEach(async (to, from, next) => {
  const title = DOCUMENT_TITLE_PREFIX

  const result = await tokenCheck()
  if (to.meta.guestOnly || result) {
    next()
  } else {
    const lang = getLangCookie()
    let msg = '로그인 이후 이용 가능합니다.'
    if (lang !== 'ko') {
      msg = 'Please Log-in'
    }
    alert(msg)
    location.href = '/'
  }

  document.title = title
})

export default router
